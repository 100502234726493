import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useIntl } from 'gatsby-plugin-intl'
import Typography from '@material-ui/core/Typography'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import { MeterSqrIcon } from '../../icons/MeterSqrIcon'
import Button from '@material-ui/core/Button'
import { useState } from 'react'
import { submitForm } from '../../utils/submitForm'
import { 
  Checkbox,
  MenuItem, 
  Select, 
  TextField, 
} from '@material-ui/core'
import axios from 'axios'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import GoogleMapsAutoComplete from '../AdressAutoComplete'
import { 
  NUMBER_OF_BEDROOMS, 
  PROPERTY_TYPE 
} from '../../utils/constants'
import { TextSearch } from '../TextSearch'
import Link from '@material-ui/core/Link'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '70vh',
    backgroundColor: '#FAE9E5',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    margin: 'auto',
  },
  label: {
    fontFamily: "'Gotham Book', serif",
  },
  alertHeading: {
    fontSize: theme.typography.pxToRem(25),
    marginBottom: theme.spacing(4),
    textTransform: 'uppercase',
    fontFamily: `'Gotham Bold', serif`,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  field: {
    padding: '0 1rem',
    marginBottom: '4rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  fieldSearch: {
    padding: '0 2rem',
    marginBottom: '4rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  formContainer: {
    [theme.breakpoints.between('xs', 'sm')]: {
      lineHeight: 3,
    },
  },
  btnContainer: {
    marginTop: theme.spacing(1),
  },
  inputClass: {
    width: '100%',
    padding: '0 .7rem',
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.9rem',
    },
  },
  containerClass: {
    marginBottom: 20,
    flex: 1,
  },
  w100: {
    width: '100%',
  },
  labelClass: {
    width: '100%',
    color: '#000',
  },
  input: {
    width: '100%',
    padding: '0 1rem',
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.9rem',
    },
  },
  inputSearch: {
    width: '100%',
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.9rem',
    },
  },
  selectContainer: {
    padding: '0 1rem',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
  },
  selectRoot: {
    width: '100%',
    borderBottom: '1.5px solid #707070',
    fontFamily: `'Gotham Book', serif`,
    fontSize: '.9rem',
    '& .MuiInputBase-input': {
      width: '100%',
    },
    '&:hover': {
      borderBottom: '1.5px solid #707070',
    },
    '&:focus': {
      borderBottom: '1.5px solid #707070',
    },
  },
  menuItem: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: '12px',
  },
  consent: {
    fontSize: '.8em',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
}));

const Square = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #FF0000;
  margin: 0px;
  background: transparent;
`
const reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const reNumber = /^(([1-9])|(([1-9])([0-9]*)))$/
const rePhoneNumber = /\s*(?:\+?(\d{1,3}))?[\W\D\s]^|()*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d[\W\D\s]*?\d)(?: *x(\d+))?\s*$/

export const CreateAlert: React.FC = () => {
  const [error, setError] = useState(['initial']);
  const [submited, setSubmited] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: null,
    lastName: null,
    email: null,
    prixMin: null,
    prixMax: null,
    surfaceMin: null,
    searchLocations: [],
    searchPropertyTypes: [],
    searchBedroomsNumber: null,
    alertGarden: 'false',
    alertEnabled: true,
    phone: null,
    form: 'FA1',
    consent: false,
  } as any);
  const classes = useStyles();
  const intl = useIntl();

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown; textContent?: any; checked?: boolean }>) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setFormValues({ ...formValues, [event.target.name || 'adresse' as string]: (event.target.name == 'consent' ? event.target.checked : event.target.value) || event.target.textContent })
      }
    },
    [formValues],
  )

  const getFrenchResultByPlaceId = async (placeId: any) => {
    if (!placeId) return null
    const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&language=fr&key=AIzaSyADYqJa3C9FujJ_he8WxlSno6RWCUGmQdc`)
    return getGoogleMapsComponents(res.data?.results?.[0])
  }
  
  const getGoogleMapsComponents = (result: any) => {
    const { address_components } = result;
    const ret: {
      numero?: string;
      rue?: string;
      ville?: string;
      codePostal?: string;
      departement?: string;
      region?: string;
      pays?: string;
    } = {};
    address_components.map(({ types = '', long_name = '' }) => {
      const type = types[0];
      switch (type) {
        case "route":
          ret.rue = long_name;
          break;
        case "street_number":
          ret.numero = long_name;
          break;
        case "locality":
          ret.ville = long_name;
          break;
        case "administrative_area_level_2":
          ret.departement = long_name;
          break;
        case "administrative_area_level_1":
          ret.region = long_name;
          break;
        case "country":
          ret.pays = long_name;
          break;
        case "postal_code":
          ret.codePostal = long_name;
          break;
        case "political":
          if (long_name.indexOf('Paris') > -1 && /[0-9]/.test(long_name)) {
            ret.codePostal = '75' + long_name.replace(/\D+/g, '').padStart(3, '0')
          }
          if (long_name.indexOf('Marseille') > -1 && /[0-9]/.test(long_name)) {
            ret.codePostal = '13' + long_name.replace(/\D+/g, '').padStart(3, '0')
          }
          break;
      }
    });
  
    return ret;
  }

  const handleChangeAC = async (event: React.ChangeEvent<{ name?: string; value: unknown }>, newValue: any) => {
    setError([])
      if (newValue) {
        await setFormValues({ ...formValues, searchLocations: newValue });
      } else {
        await setFormValues({ ...formValues, searchLocations: null });
      }
    };

  const onSubmit = async () => {
    const validateField: any = Object.keys(formValues).filter((key: string) => {
      if (key === 'email' && !String(formValues[key]).match(reEmail)) return true

      if (key === 'prixMax' && !String(formValues[key]).match(reNumber)) return true
      if (key === 'surfaceMin' && !String(formValues[key]).match(reNumber)) return true

      if (key === 'phone' && !String(formValues[key]).match(rePhoneNumber)) return true

      if (key === 'firstName' && (!formValues[key] || String(formValues[key]).length == 0)) return true
      if (key === 'lastName' && (!formValues[key] || String(formValues[key]).length == 0)) return true
      if (key === 'searchBedroomsNumber' && (!formValues[key] || String(formValues[key]).length == 0)) return true

      if (key === 'searchPropertyTypes' && (!formValues[key] || !Array.isArray(formValues[key]) || formValues[key].length == 0)) return true
      if (key === 'searchLocations' && (!formValues[key] || !Array.isArray(formValues[key]) || formValues[key].length == 0)) return true
      
      if (key === 'consent' && !formValues[key]) return true
      
      return false
    })

    if (validateField.length || !formValues?.searchLocations || !Array.isArray(formValues?.searchLocations)) {
      setError(validateField);
    } else {
      const searchLocations = await Promise.all(formValues.searchLocations.map(async (loc: { description: string }) => {
        const g = await geocodeByAddress(loc?.description);
        const result = await getFrenchResultByPlaceId(g[0].place_id)
        let location: any = {};
        if (result?.pays) location["country"] = unescape(encodeURIComponent(result?.pays));
        if (result?.departement) location["department"] = unescape(encodeURIComponent(result?.departement));
        if (result?.region) location["region"] = unescape(encodeURIComponent(result?.region));
        if (result?.ville && !result?.codePostal) location["town"] = unescape(encodeURIComponent(result?.ville));
        if (result?.codePostal) location["zipCode"] = unescape(encodeURIComponent(result?.codePostal));

        const ret = JSON.stringify(location);
        return btoa(ret);
      }))

      const result = await submitForm({
        ...formValues, 
        searchLocations: Array.from(new Set(searchLocations)),
      });
      
      if (result) {
        setSubmited(true);
        setError(['initial']);
      };
    }
  }
  
  return (
    <Grid container className={classes.root}>
      <Grid item md={7} xs={10}>
        <Grid container justifyContent="center" item xs={12}>
          <Typography className={classes.alertHeading}>
            {intl.formatMessage({ id: 'createYourAlert' })}
          </Typography>
        </Grid>
        <Grid container className={classes.formContainer}>
          <Grid item md={12} xs={12}>
            <Box className={classes.field}>
              <GoogleMapsAutoComplete 
                InputLabelProps={{ className: classes.labelClass }}
                className={classes.input}
                error={error.includes('searchLocations')}
                hideAddresses={true}
                inputProps={{ className: classes.inputClass }}
                multiple={true}
                onChange={handleChangeAC}
                placeholder={'localisation'}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.fieldSearch}>
              <TextSearch
                className={classes.inputSearch}
                convertInToTriplets={true}
                icon={() => <EuroSymbol style={{ fontSize: '1rem' }} />}
                iconPosition="right"
                name="prixMin"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'options.minPrice' })}
                value={formValues.prixMin}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.fieldSearch}>
              <TextSearch
                className={classes.inputSearch}
                convertInToTriplets={true}
                error={error.includes('prixMax')}
                icon={() => <EuroSymbol style={{ fontSize: '1rem' }} />}
                iconPosition="right"
                name="prixMax"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'options.maxPrice' })}
                value={formValues.prixMax}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.fieldSearch}>
              <TextSearch
                className={classes.inputSearch}
                convertInToTriplets={true}
                error={error.includes('surfaceMin')}
                icon={() => <MeterSqrIcon />}
                iconPosition="right"
                name="surfaceMin"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'options.minArea' })}
                value={formValues.surfaceMin}
              />
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className={classes.field}>
              <div className={classes.selectContainer}>
                <Select
                  classes={{ root: classes.selectRoot }}
                  displayEmpty={true}
                  error={error.includes('searchPropertyTypes')}
                  fullWidth
                  multiple={true}
                  name={'searchPropertyTypes'}
                  onChange={handleChange}
                  renderValue={(value: any) => value?.length ? Array.isArray(value) ? <>{value.map(val => intl.formatMessage({ id: 'propertyTypeEnum.' + val })).join(', ')}</> : <>{intl.formatMessage({ id: 'propertyTypeEnum.' + value })}</> : <>{intl.formatMessage({ id: 'propertyType' })}</>}
                  value={formValues.searchPropertyTypes}
                >
                  {PROPERTY_TYPE.map((each) => (
                    <MenuItem key={each.value} value={each.value} className={classes.menuItem}>
                      {intl.formatMessage({ id: 'propertyTypeEnum.' + each.value })}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.field}>
              <div className={classes.selectContainer}>
                <Select
                  classes={{ root: classes.selectRoot }}
                  displayEmpty={true}
                  error={error.includes('searchBedroomsNumber')}
                  fullWidth
                  name={'searchBedroomsNumber'}
                  onChange={handleChange}
                  renderValue={(value: any) => value?.length ? Array.isArray(value) ? <>{value.join(', ')}</> : <>{intl.formatMessage({ id: 'numberOfBedroomsEnum.' + value })}</> : <>{intl.formatMessage({ id: 'numberOfBedrooms' })}</>}
                >
                  {NUMBER_OF_BEDROOMS.map((each) => (
                    <MenuItem key={each.value} value={each.value} className={classes.menuItem}>
                      {intl.formatMessage({ id: 'numberOfBedroomsEnum.' + each.value })}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className={classes.field}>
              <TextField
                className={classes.input}
                error={error.includes('lastName')}
                name="lastName"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'lastName' })}
              />
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className={classes.field}>
              <TextField
                className={classes.input}
                error={error.includes('firstName')}
                name="firstName"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'firstName' })}
              />
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className={classes.field}>
              <TextField
                className={classes.input}
                error={error.includes('email')}
                name="email"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'email' })}
                type="email"
              />
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box className={classes.field}>
              <TextField
                placeholder={intl.formatMessage({ id: 'yourNumber' })}
                name="phone"
                error={error.includes('phone')}
                onChange={handleChange}
                className={classes.input}
                type="tel"
              />
            </Box>
          </Grid>
          <Grid xs={12} item className={classes.consent} justifyContent="center">
            <Grid style={{textAlign: 'center', paddingTop: '8px', paddingBottom: '8px'}}>
              <span>
                {intl.formatMessage({ id: 'legalConsent.createAlert.agree' })}
                <Checkbox
                  size="small"
                  icon={formValues.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />}
                  checkedIcon={<FullSquare />}
                  checked={formValues.consent}
                  onChange={handleChange}
                  name={'consent'}
                  style={{padding: '0 0 0 4px'}}
                />
              </span>
            </Grid>
            <Grid style={{textAlign: 'center', paddingTop: '12px', paddingBottom: '8px'}}>
              <span>
                {intl.formatMessage({ id: 'legalConsent.createAlert.moreInformation' })}
                <Link
                  href={
                    '/' +
                    intl.locale +
                    '/legal#privacy-policy'
                  }
                  underline="none"
                >
                  {intl.formatMessage({ id: 'clickHere' })}
                </Link>
              </span>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" item xs={12} className={classes.btnContainer}>
            <div>
              {submited ? (
                intl.formatMessage({ id: 'send_confirm' })
              ) : (
                <Button variant="outlined" style={{ textTransform: 'initial' }} onClick={onSubmit}>
                  {intl.formatMessage({ id: 'send' })}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

