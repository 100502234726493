import React from 'react'
import { Layout } from '../component/Layout'
import { PageProps } from 'gatsby'
import { CreateAlert } from '../component/page_component/CreateAlert'
import { useIntl } from 'gatsby-plugin-intl'

const Buy: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()

  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleAlert' })}>
      <CreateAlert />
    </Layout>
  )
}

export default Buy
